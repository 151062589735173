import React, { useMemo, lazy, Suspense } from "react";
import { useLocation } from "react-router";
import { Page } from "../components/Page";
import { ArticleWrapper } from "../components/articles/components/ArticleWrapper";
import data from "../data/articles.json";

const importArticle = (id: string) => {
  return lazy(() => import(`../components/articles/${id}`));
};

export const ArticlePage = () => {
  const location = useLocation();
  const article = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    return data.find((article) => article.id === id);
  }, [location.search]);

  if (!article)
    return (
      <>
        <h1>Článek nebyl nalezen</h1>
        <p>Prosím zkontrolujte zda je adresa URL správná</p>
      </>
    );

  const DynamicArticle = importArticle(article.id);

  return (
    <Page backgroundFixed backgroundSrc="https://files.catbox.moe/8o0hnq.png">
      <ArticleWrapper {...article}>
        <Suspense fallback={<div>Loading...</div>}>
          <DynamicArticle />
        </Suspense>
      </ArticleWrapper>
    </Page>
  );
};
