import { Link } from "react-router-dom";
import { ArticleShow } from "../components/ArticleShow";
import CustomAudioPlayer from "../components/AudioPlayer";
import { LandingPageArticle } from "../components/LandingPageArticle";
import Reward from "../components/Reward";

export const LandingPage = () => {
  return (
    <div
      style={{
        backgroundImage: 'url("/assets/background.jpg")',
        fontFamily: "Lora",
      }}
      className="relative w-full h-full font-serif bg-fixed bg-center bg-no-repeat bg-cover"
    >
      <div className="absolute inset-0 z-10 bg-white bg-opacity-60" />

      <br />
      <div className="relative z-20">
        <header className="z-20 bg-transparent mr-auto ml-auto text-center mt-[50px]">
          <p className="text-gray-400 tracking-[4px] text-15px md:text-[17px] lg:text-[25px] mt-15">
            S vědomím celku a s úctou k jednotlivostem
          </p>

          <h1 className="tracking-[14px] text-gray-500 text-[40px] md:text-[60px] lg:text-[90px] md:mt-[0px] lg:mt-[-30px] mr-auto ml-auto">
            Hlasy Země
          </h1>

          <p className="text-gray-400 tracking-[4px] text-15px md:text-[17px] lg:text-[25px] mb-8">
            se rozléhají zlínským krajem
          </p>

          <div className="flex justify-center gap-5 md:gap-0 flex-wrap md:mt-0 lg:mt-[-25px]">
            {[
              "Společná setkání",
              "Vzájemná podpora",
              "Respektující komunikace",
            ].map((el: string) => (
              <Reward text={el} key={el} />
            ))}
          </div>
        </header>

        <section className="mr-auto ml-auto w-[300px] md:w-[500px] lg:w-[900px] text-center mt-[95px] mb-12">
          <LandingPageArticle />

          <div className="mx-auto mt-2 w-fit">
            <CustomAudioPlayer src="https://files.catbox.moe/j355n1.mp4" />
          </div>
        </section>

        <section className="flex flex-col gap-3 px-20 pb-10 mx-auto w-fit">
          <h1 className="text-[#857e71] text-2xl text-center">Nově přidáno</h1>
          <ArticleShow articleCount={3} />
          <Link to="/articles" className="text-[#857e71] text-lg">
            Více článků
          </Link>
        </section>
      </div>
    </div>
  );
};
