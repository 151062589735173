import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Navbar } from "./components/Navbar";

import "./index.css";

import { JoinButton } from "./components/JoinButton";
import { ArticlePage } from "./pages/ArticlePage";
import { ArticlesPage } from "./pages/ArticlesPage";
import { ContactPage } from "./pages/ContactPage";
import { Photogallery } from "./pages/GalleryPage";
import { IdeasPage } from "./pages/IdeasPage";
import { LandingPage } from "./pages/LandingPage";
import { NewTodayPage } from "./pages/NewTodayPage";
import ThanksPage from "./pages/ThanksPage";
import { WorkMeaningPage } from "./pages/WorkMeaning";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/kontakt",
    element: <ContactPage />,
  },
  {
    path: "/fotogalerie",
    element: <Photogallery />,
  },
  {
    path: "/ideas",
    element: <IdeasPage />,
  },
  {
    path: "/work-meaning",
    element: <WorkMeaningPage />,
  },
  {
    path: "/new-today",
    element: <NewTodayPage />,
  },
  {
    path: "/articles",
    element: <ArticlesPage />,
  },
  {
    path: "/article",
    element: <ArticlePage />,
  },
  {
    path: "/thanks",
    element: <ThanksPage />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Navbar />
    <RouterProvider router={router} />
    <JoinButton />
  </React.StrictMode>
);
