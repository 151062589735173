import { Page } from "../components/Page";
import { ContentWrapper } from "../components/articles/components/ContentWrapper";
import {
  Paragraph,
  Subtitle,
} from "../components/articles/components/Typography";

export const NewTodayPage = () => {
  return (
    <Page
      className="font-serif"
      backgroundSrc="https://files.catbox.moe/8o0hnq.png"
    >
      <ContentWrapper>
        <Paragraph center italic>
          Staré postupně odchází, avšak člověk dneška má využít ještě čas a co
          nejvíce se ze starého poučit
        </Paragraph>
        <Paragraph center italic>
          Oprostit se od něj neznamená toužebně žít neustále s myšlenkami na
          nové a čekat
        </Paragraph>
        <Paragraph center italic>
          Ono nové bude k nám velice přísné a bude vyžadovat po člověku úplné
          dodržování přírodních zákonů
        </Paragraph>
        <Paragraph center italic>
          To může odradit mnoho těch, kteří nejsou k Pravdě upřímní
        </Paragraph>
        <Paragraph center italic>
          Ale dodržování těchto zákonů probudí v člověku nadšení a povznese jej
          vysoko nad staré
        </Paragraph>
        <Paragraph center italic>
          Každý může už nyní vyciťovat prvotní volání k nám lidem „Ty musíš“
        </Paragraph>
        <Paragraph center italic>
          Už přestává platit „Ty můžeš“
        </Paragraph>
        <Paragraph center italic>
          Jen plná zodpovědnost za své myšlenky, slova a činy bude vstupní
          branou do nového
        </Paragraph>
        <Paragraph center italic>
          V něm nebude nic zadarmo, jak si mnozí mohou myslet
        </Paragraph>
        <Paragraph center italic>
          Přinese ale štěstí netušené nádhery těm, kteří budou následovat cit
          svého ducha
        </Paragraph>

        <Subtitle center italic>
          Co je však ono nové?
        </Subtitle>
        <Paragraph center italic>
          Každý ať chce nebo ne, cítí uvnitř svého srdce, že ve světě dochází k
          přeměnám
        </Paragraph>
        <Paragraph center italic>
          A tak jako dějiny celé Země dokládají nespočet převratů napříč touto
          planetou
        </Paragraph>
        <Paragraph center italic>
          tak nyní přichází něco mnohem hlubšího a významnějšího
        </Paragraph>
        <Paragraph center italic>
          Tlak na člověka ke změně se stupňuje každým dnem silněji a silněji a
          jsme čím dále více
        </Paragraph>
        <Paragraph center italic>
          tlačeni do míst, kde už začíná platit pouze „Ty musíš“
        </Paragraph>

        <Subtitle center italic>
          Pouze to je cesta k novému
        </Subtitle>

        <Paragraph center italic>
          Mnohem silněji však tento tlak pociťují ti, kteří jsou srdce toužícího
          po něčem vyšším
        </Paragraph>
        <Paragraph center italic>
          a kteří touží po Světle a celou svou silou se snaží najít Pravdu
        </Paragraph>
        <Paragraph center italic>
          Tento tzv. tlak je milost, neboť jsou spousty těch, kteří jdou stále
          jako slepci
        </Paragraph>
        <Paragraph center italic>
          držící se starého
        </Paragraph>
        <Paragraph center italic>
          Nebo kteří jen čekají na nové s přesvědčením, že oni sami se nemusí
          změnit a že
        </Paragraph>
        <Paragraph center italic>
          nové jim tak spadne do klína bez jejich vlastního přičinění
        </Paragraph>

        <Subtitle center italic>
          Ono nové však je probudí, ale pak už může býti příliš pozdě
        </Subtitle>
        <Paragraph center italic>
          Doba k tomu začíná být pomalu zde a stupňuje se každým dnem
        </Paragraph>
        <Paragraph center italic>
          Kdo to ještě nevidí
        </Paragraph>
        <Paragraph center italic>
          nechce si přiznat, že stále lpí na starém současném světě
        </Paragraph>

        <Subtitle center italic>
          My však hledáme ty, kteří chtějí jít v přesvědčení a zodpovědnosti
        </Subtitle>
        <Paragraph center italic>
          s námi vstříc novému a to změnou sebe sama
        </Paragraph>

        <Subtitle center italic>
          Úsilím o ušlechtilou vroucnost
        </Subtitle>
      </ContentWrapper>
    </Page>
  );
};
