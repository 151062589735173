import { Page } from "../components/Page";
import {
  Paragraph,
  Subtitle,
} from "../components/articles/components/Typography";

export const WorkMeaningPage = () => {
  return (
    <Page
      backgroundSrc="https://files.catbox.moe/8o0hnq.png"
      customOpacity={70}
      className="font-serif"
    >
      <Paragraph>
        Smysl stránek Hlasy Země je především v podpoře lidí napříč městem.
        Rozproudění myšlenek na pospolitost a počáteční podnět ke znovuoživení
        duše města. Navrácení úsilí po vznešených ideálech na společenské a
        vztahové úrovni a snaha o poskytnutí vědomí vzájemné podpory pro ty,
        kteří chtějí držet pospolu.
      </Paragraph>

      <Paragraph>
        Zakladatel a úžasný člověk Tomáš Baťa dokázal vtisknout našemu městu
        pospolitost, řád a společně s pevnou vírou v sílu celku oživit
        ušlechtilé pojmy v čin.
      </Paragraph>

      <Subtitle center italic>
        Hlasy Země chtějí probudit v lidech zde žijících vzpomínku na
        opravdovost ducha Zlína pro ty, kteří touží po Světle.
      </Subtitle>

      <Subtitle italic center>
        Naše cíle
      </Subtitle>

      <Paragraph center italic>
        Věříme, že myšlenka má velkou moc, a proto chceme pro začátek rozproudit
        a zesílit uvnitř města shromaždiště právě takových vláken, která budou
        pro každého podpůrná
      </Paragraph>

      <Paragraph center italic>
        Už jen vědomí toho musí být pro člověka zde žijícího posilující
      </Paragraph>

      <Subtitle center italic>
        Oživme ducha pospolitosti
      </Subtitle>

      <Paragraph center italic>
        Dále bychom chtěli uspořádávat společná setkání pro lidi stejného či
        podobného smýšlení a hodnot
      </Paragraph>

      <Paragraph center italic>
        Podaří - li se nám to, chtěli bychom časem ať už zde, nebo na jiné
        platformě sdružovat poctivé řemeslníky, kteří chtějí tvořit a šířit
        krásu
      </Paragraph>

      <Paragraph center italic>
        Tato doba sebou nese spousty přeměn a cítíme potřebu o tom otevřeně
        diskutovat a vytvářet podpůrné praktické nástroje
      </Paragraph>

      <Paragraph center italic>
        které už v budoucnosti můžou fungovat samostatně a nezávisle na těchto
        stránkách.
      </Paragraph>

      <Subtitle center italic>
        Nechceme a nikdy nebudeme tvořit žádnou organizaci ani spolek
      </Subtitle>

      <Paragraph center italic>
        Jde nám především, a to zdůrazňuji, o znovunavrácení ducha pospolitosti
        do města Zlína
      </Paragraph>

      <Paragraph center italic>
        Budeme velice vděční, když přijdete se svými nápady
      </Paragraph>

      <Subtitle center italic>
        Právě jsme začali a Hlasy Země jsou v počátečním zrodu
      </Subtitle>
    </Page>
  );
};
