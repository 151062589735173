import { ContentWrapper } from "./articles/components/ContentWrapper";
import { Paragraph, Subtitle, Title } from "./articles/components/Typography";

export const LandingPageArticle = () => {
  return (
    <ContentWrapper>
      <Subtitle center>CHCEŠ-LI TAKÉ</Subtitle>

      <Paragraph center>
        S námi hledat cesty, jak ve Zlíně a jeho okolí probouzet harmonii a
        důvěru mezi sebou
      </Paragraph>
      <Paragraph center>
        Vzájemně se vzdělávat tak, abychom stáli neustále v úctě jeden k druhému
      </Paragraph>
      <Paragraph center>
        a pomáhali si tak narovnávat své vlastní hodnoty do té správné míry
      </Paragraph>

      <Subtitle center>VIDÍŠ-LI TAKÉ, ŽE</Subtitle>
      <Paragraph center>
        přírodní zákony této Země v sobě nesou spousty souvislostí s
        mezilidskými vztahy
      </Paragraph>
      <Paragraph center>
        a že jak fyzika, chemie, tak i jiné obory nám nápadně ukazují, co je
        zralá láska
      </Paragraph>

      <Subtitle center>JSI-LI TEN, KDO TOUŽÍ PO</Subtitle>
      <Paragraph center>společném sdílení s těmi, kteří také chtějí</Paragraph>
      <Paragraph center>
        hledat souvislosti mezi všemi podstatami této Země
      </Paragraph>
      <Paragraph center>
        a nalézat tak jejich opravdovou ryzost v té čisté podobě
      </Paragraph>

      <Title center>HARMONIE - SJEDNOCENÍ - DŮVĚRA</Title>
    </ContentWrapper>
  );
};
